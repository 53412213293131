import {DDMMYYYYFormatting} from "@/util"
import CourseModel from "@/app/admin/models/courseModel"
import CMExamConfig from "../../../course_managment/classes/CMExamConfig"
function DeleteMS(hours) {
  if(!hours) return
  let splited =  hours.split(':')
  return [splited[0], splited[1]].join(':')
}

export const REQUEST_ARGS = {
  COURSE_LOCATION: 'course_location',
  COURSE: {
    FIELD: "course",
    NESTED: {
      ID: 'id',
      CITY: 'city',
      date: 'date'
    }
  },
  COURSE_DATE: 'course_date',
  COMMENT: 'comment',
  EXAM_COMMENT: 'exam_comment',
}

export default class ReservationRequestDTO {
  constructor(item = {
    [REQUEST_ARGS.COURSE.FIELD]: {},
    added_manually: true,
  }) {
    this.ID = item.id
    this.examDate = DDMMYYYYFormatting(item.exam_date)
    this.timeFrom = DeleteMS(item.exam_time_from)
    this.timeTo = DeleteMS(item.exam_time_to)
    this.location = item.location
    this.course = item[REQUEST_ARGS.COURSE.FIELD] ? new CourseModel(item[REQUEST_ARGS.COURSE.FIELD]) : {}
    this.exam = new CMExamConfig(item.course_exam)
    this.comment = item[REQUEST_ARGS.COMMENT]
    this.examComment = item[REQUEST_ARGS.EXAM_COMMENT]
    this.isExchange = item.is_exchange
    this.slotsCount = item.slots_count
    this.onHold = item.is_on_hold || false,
    this.addedManually = item.added_manually || false
    this.origin = item.origin
    this.isLive = item.is_live
  }
}