import { convertToShortMonth } from "@/util";

export default class CourseDayJourneyGrouped {
  constructor(candidates, list) {
    this.candidates = candidates;
    this.list = list;
  }

  groupedList(journeys) {
    let list = {...this.list};
    if (!list) return {};
    journeys.forEach(journeyDay => {
      list[journeyDay.id] = {
        label: journeyDay.journey.internal_name,
        sections: journeyDay.sections,
        items: [],
        journeyDayID: journeyDay.journey.id
      };
    });
    this.candidates.forEach(candidate => {
      if (!candidate.journey_day.journey) return;
      let group = candidate.journey_day.id;
      candidate.group = group;
      if (group in list) {
        list[group].items.push(candidate);
      } else {
        list[group] = {
          label: `${candidate.journey_day.journey.internal_name}`,
          items: [candidate]
        };
      }
    });

    return list;
  }

  groupJourneys(journeyDays) {
    let hashMap = new Map();
    let groupedJourneys = [];
    journeyDays.forEach(journey => {
      let courseDayDate = journey.journey_days[0].course_day.date; // need grouping by first day in course_days

      if (hashMap.has(courseDayDate)) {
        hashMap.set(courseDayDate, {
          ...hashMap.get(courseDayDate),
          items: [...hashMap.get(courseDayDate).items, journey]
        });
      } else hashMap.set(courseDayDate, {
        journeyID: journey.id,
        journeyName: journey.internal_name,
        monthDay: new Date(courseDayDate).getDate(),
        date: courseDayDate,
        month: convertToShortMonth(courseDayDate),
        items: [journey]
      });

      journey.journey_days.sort(function (courseDayA, courseDayB) { // sort items by date in CourseDay
        return new Date(courseDayA.course_day.date) - new Date(courseDayB.course_day.date);
      });
    });

    for (let [, value] of hashMap) {
      groupedJourneys.push(value);
    }

    return groupedJourneys;
  }
}
