import auth from '@/services/CRMinitAuth';

const quickReserve = payload =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/quick_add_exam_request/`, payload);

const examRequests = (id) =>
  auth.get(`${process.env.VUE_APP_CRM_API}candidates/actions/${id}/get_exam_requests/`);

const uploadCoursePhoto = (data, params) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/upload_course_photo/`, data, params);

const removeCoursePhoto = (data, params) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/remove_course_photo/`, data);

/**
 *
 * BULK ACTIONS
 *
 **/

const bulkAssignJourney = (data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/bulk_assign_journeys/`, data);

const bulkAssignTransportationConfig = (data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/bulk_assign_transportation_config/`, data);

const bulkSetZoomPresentie = (data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/bulk_zoom_set_registrant/`, data);

const bulkSetZoomAllPresentie = (data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/bulk_zoom_set_all_registrant/`, data);

const bulkAssignTransportType = (data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/bulk_assign_transport_type/`, data);

const bulkDownloadCoursePhoto = (data, params) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/download_course_photos/`, data, params);

const sendCertificateEmail = (data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/send_certificate_email/`, data);


export default {
  quickReserve,
  bulkAssignJourney,
  bulkAssignTransportationConfig,
  bulkSetZoomPresentie,
  bulkSetZoomAllPresentie,
  bulkAssignTransportType,
  examRequests,
  sendCertificateEmail,
  uploadCoursePhoto,
  bulkDownloadCoursePhoto,
  removeCoursePhoto
};
