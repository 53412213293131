import CourseDay from './courseDay.model';
import { EMAIL_TEMPLATE_ARGS } from "@/app/admin/modules/candidates/core/models/CandidatesEmailTemplateModel"
import HandleFailedModel from "../../candidates/core/models/candidatesHandleFailedModel"
import { COURSE_DAY_PAGES } from './courseDay-const';

export default class CandidatesNewService {
  constructor(api) {
    this.api = api;
  }

  async candidates(query, page) {
    try {
      let request = this._listSwitcher(page);
      let res = await request({...query, course_day: query.course_day});
      if (!res.results) throw new Error('Something wrong and candidates not loading. Please, reload page or try later.')
      return {
        results: res.results.map(item => new CourseDay(item)),
        pagination: {
          count: res.count,
          size: res.size,
          next: res.next,
          previous: res.previous
        },
        candidates_counts: res.candidates_counts,
        exams_statistic: res.exams_statistic
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  _listSwitcher(page) {
    let apiRequest = this.api.candidatesAllWillCome;
    switch (page) {
    case COURSE_DAY_PAGES.ALL_WILL_COME:
      apiRequest = this.api.candidatesAllWillCome;
      break;
    case COURSE_DAY_PAGES.ALL_WILL_NOT_COME:
      apiRequest = this.api.candidatesAllWillNotCome;
      break;
    default:
      break;
    }
    return apiRequest;
  }

  async candidatesAllWillCome(query) {
    let _query = {...query, city: undefined};
    return await this.api.candidatesAllWillCome(_query);
  }

  async candidatesAllWillNotCome(query) {
    let _query = {...query, city: undefined};
    return await this.api.candidatesAllWillNotCome(_query);
  }

  async getFullListIDs(query, page) {
    try {
      let request = this._listSwitcher(page);
      let res = await request(query);
      if (!res) throw new Error('Something went wrong and full candidates list is not loading. Please, reload page or try later.')
      return res.map(itemId => {
        return {
          ID: itemId
        };
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async saveNote(ID, data) {
    try {
      let res = await this.api.patch(ID, {notes: data});
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async bulkAssignTransportationConfig(ids, payload) {
    try {
      let data = ids.map(id => {
        let _apiData = {exam: id};
        if (!payload.formData.exam_start_time || !payload.formData.exam_finish_time || !payload.formData.transport_type)
          return _apiData;
        return { ..._apiData, transportation_config: payload.formData, };
      });
      return await this.api.bulkAssignTransportationConfig(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async saveTransportConfigsForCandidates(list) {
    const requestData = list.map(config => config.getApiData());
    try {
      await this.api.bulkAssignTransportationConfig(requestData);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async markAsProcessed(ids) {
    try {
      return await this.api.markAsProcessed({ids: ids});
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async checkReservationQueueBulk(ids) {
    try {
      return await this.api.checkReservationQueueBulk({ids});
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async changeCourseBulk(IDs, data) {
    try  {
      return await this.api.changeCourseBulk({
        ids: IDs,
        course_day: data.course.ID
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sendEmail(ID, payload) {
    try {
      let data = {
        [EMAIL_TEMPLATE_ARGS.LABEL]: payload.label,
        [EMAIL_TEMPLATE_ARGS.SUBJECT]: payload.subject || '',
        [EMAIL_TEMPLATE_ARGS.CONTENT]: payload.content || ''
      };
      let res = await this.api.sendEmail(ID, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sendEmailBulk(IDs, payload) {
    try {
      let data = {
        ids: IDs,
        [EMAIL_TEMPLATE_ARGS.LABEL]: payload.label,
        [EMAIL_TEMPLATE_ARGS.SUBJECT]: payload.subject || '',
        [EMAIL_TEMPLATE_ARGS.CONTENT]: payload.content || ''
      };
      let res = await this.api.sendEmailBulk(data)
      return {
        error: res.error,
        failedCandidates: res.failed_candidates.map(item => new HandleFailedModel(item))
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async assignJourneyBulk(data) {
    try  {
      return await this.api.bulkAssignJourney(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async find(ID) {
    try {
      return await this.api.find(ID);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async put(ID, model) {
    try {
      return await this.api.put(ID, model.getApiData());
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async assignLabels(candidates, labels) {
    try {
      return await this.api.assignLabels({
        candidates: candidates,
        labels: labels
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async unAssignLabels(candidates, labels) {
    try {
      return await this.api.unAssignLabels({
        candidates: candidates,
        labels: labels
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateLabels(ID, data) {
    try {
      return await this.api.patch(ID, {labels: data})
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sendExchange({ids, exchangeRequests, content, subject, isExchange = false}) {
    try {
      let res = await this.api.sendExchange({ids: ids, exams: exchangeRequests, content: content, subject: subject, is_exchange: isExchange});
      return {
        error: res.error,
        failedCandidates: res.failed_candidates.map(item => new HandleFailedModel(item))
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sync(ids) {
    try {
      return await this.api.sync({ids});
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
